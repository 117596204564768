import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tagsWidgetContainer: {
    marginTop: '1em',
    border: '0.5px solid #D4D4D4',
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      width: "100%",
    }
  },
  titleContainer: {
    backgroundColor: '#3C465F',
    padding: '16px',
    boxShadow: 'inset 0px -1px 0px rgba(170, 169, 228, 0.3)'
  },
  tagsContainer: {
    backgroundColor: '#FFFFFF',
    padding: '12px 16px 0px',
    paddingBottom: '4px'
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF'
  },
  tag: {
    fontFamily: 'Poppins',
    color: '#3C465F',
    fontWeight: '500',
    backgroundColor: '#F7F5E7',
    fontSize: '12px',
    lineHeight: '16px',
    border: '0.5px solid #3C465F',
    borderRadius: '48px',
    padding: '8px',
    display: 'block',
    marginBottom: '8px',
    marginRight: '8px',
    textDecoration: 'none'
  }

}));

function TagsWidget(props) {
  const { } = props;

  const classes = useStyles();

  return (
    <div className={classes.tagsWidgetContainer}>
      <div className={classes.titleContainer}>
        <label className={classes.title}>Tags</label>
      </div>
      <Grid container className={classes.tagsContainer}>
        <Grid item>
          <a href="/forum/tags/familyfinances" className={classes.tag}>#familyfinances</a>
        </Grid>
        <Grid item>
          <a href="/forum/tags/sustainability" className={classes.tag}>#sustainability</a>
        </Grid>
        <Grid item>
          <a href="/forum/tags/retirement" className={classes.tag}>#retirement</a>
        </Grid>
        <Grid item>
          <a href="/forum/tags/divorce" className={classes.tag}>#divorce</a>
        </Grid>
        <Grid item>
          <a href="/forum/tags/tiredparents" className={classes.tag}>#tiredparents</a>
        </Grid>
        <Grid item>
          <a href="/forum/tags/caringforparents" className={classes.tag}>#caringforparents</a>
        </Grid>
        <Grid item>
          <a href="/forum/tags/sustainablesavers" className={classes.tag} >#sustainablesavers</a>
        </Grid>
        <Grid item>
          <a href="/forum/tags/relelliousrenters" className={classes.tag} >#relelliousrenters</a>
        </Grid>
        <Grid item>
          <a href="/forum/tags/givinggrandparents" className={classes.tag} >#givinggrandparents</a>
        </Grid>
        <Grid item>
          <a href="/forum/tags/dependabledads" className={classes.tag} >#dependabledads</a>
        </Grid>
        <Grid item>
          <a href="/forum/tags/annoyedselfemployed" className={classes.tag} >#annoyedselfemployed</a>
        </Grid>
      </Grid>
    </div>
  );
}

export default TagsWidget;
